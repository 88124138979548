<template>
  <div class="tags-input d-flex flex-wrap">
    <div
      v-for="(tag, index) in tagsArray"
      :key="index"
      class="tags-input__tag rounded-pill"
    >
      {{ tag }}
    </div>
    <input
      type="text"
      placeholder="Add some tags"
      class="tags-input__text"
      @keyup="addTag"
      @keyup.delete="removeLastTag"
    />
  </div>
</template>

<script>
export default {
  props: {
    tagsArray: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    addTag(event) {
      event.preventDefault();
      if (event.code == "Enter" && event.target.value) {
        this.$emit("addTag", event.target.value);
        console.log(event.target.value);
        event.target.value = "";

        //console.log("yes");
      }
    },
    removeLastTag() {
      // event.preventDefault();
      if (event.target.value.length === 0) {
        this.$emit("removeLastTag");
      }
    },
  },
};
</script>

<style scoped>
.tags-input {
  width: 100%;
  min-height: 50px;
  max-height: auto;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 0.9rem;
  box-sizing: border-box;
  display: flex;
}

.tags-input__tag {
  height: 30px;
  float: left;
  margin-right: 8px;
  margin-top: 10px;
  line-height: 30px;
  padding: 0 10px;
  background: #b3b7d6;
}

.tags-input__tag:hover {
  cursor: pointer;
  background: #c8cbc8;
}

.tags-input__text {
  border: none;
  outline: none;
  background: none;
  line-height: 50px;
  font-size: 0.9rem;
}
</style>
