export default {
  data() {
    return {
      businessCategory: ["Individual", "Company"],
      TagsArray: [],
      accountingCode: [],
      paymentTerms: ["Net 30", "Net 45", "Net 60", "Net 90"],
      country: ["Nigeria", "Ghana", "USA"],
      currency: ["NGN", "USD", "GBP", "EUR"],
      industry: [
        "Advertising",
        "Accounting",
        "Insurance",
        "Maintenace",
        "Legal",
        "Telecoms",
        "Utilities",
        "Rent",
        "Salaries",
        "Travel",
        "Transportation",
        "Charity",
        "Software",
        "Licenses & permits",
        "Raw material",
        "Employee Loans",
      ],
      customerIndustry: [
        "agricultural",
        "ecommerce",
        "education",
        "financial",
        "gaming",
        "health",
        "hospitality",
        "legal",
        "logistics",
        "manufacturing",
        "NGO",
        "others",
        "Retail",
        "Wholesale",
        "Restaurants",
        "Hospitals",
        "Construction",
        "Insurance",
        "Unions",
        "RealEstate",
        "FreelanceProfessional",
        "OtherProfessionalServices",
        "OnlineRetailer",
        "OtherEducationServices",
      ],

      taxCategory: [
        "Commission",
        "Consultancy",
        "Technical",
        "Agency",
        "Contracts",
        "Rent",
        "Management",
      ],
      applyWithholding: [true, false],
    };
  },
  methods: {
    //Add new Tags to the TagsArray
    addTag(value) {
      let val = value.trim();

      this.TagsArray.push(val);
      console.log(JSON.stringify(this.TagsArray));
    },

    //Remove Tags from the TagsArray
    removeLastTag() {
      this.TagsArray.splice(this.TagsArray.length - 1, 1);
    },
  },
};
