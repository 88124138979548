<template>
  <div>
    <v-dialog v-model="dialog" max-width="574">
      <v-card color="#f8f7f4" class="rounded-lg">
        <v-card-title
          class=""
          style="background: #ffffff; border-radius: 8px 8px 0px 0px"
        >
          <span
            style="
              font-family: Inter;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 19px;
              color: #19283d;
            "
            >Invite Vendor</span
          >
          <v-spacer></v-spacer>
          <v-btn icon class="pa-1">
            <v-icon @click="dialog = false" class="text-bolder" color="#596A73">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <div class="px-0 px-md-8 d-flex" style="background: #f3eee8">
          <v-tabs v-model="tab" background-color="#f3eee8" slider-size="4">
            <v-tab v-for="item in VendorTabs" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
        </div>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card style="background-color: transparent" flat>
              <div class="my-7 mx-4 mx-md-10">
                <p class="pa-0 ma-0 vendor-desc">
                  Include details about this vendor
                </p>
              </div>
              <v-form ref="form" lazy-validation>
                <v-row class="mx-4 mx-md-10">
                  <v-col cols="12" class="px-0">
                    <v-text-field
                      v-model="vendorDetails.business"
                      :rules="[rules.required]"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="Enter Vendor Business Name"
                    >
                    </v-text-field
                  ></v-col>
                </v-row>
                <v-row class="mx-4 mx-md-10">
                  <v-col md="6" class="pl-0"
                    ><v-text-field
                      v-model="vendorDetails.first_name"
                      :rules="[rules.required]"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="First Name"
                    ></v-text-field
                  ></v-col>
                  <v-col md="6" class="pr-0"
                    ><v-text-field
                      v-model="vendorDetails.last_name"
                      :rules="[rules.required]"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="Last Name"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <v-row class="mx-4 mx-md-10">
                  <v-col md="6" class="pl-0"
                    ><v-text-field
                      :rules="[rules.required, rules.email]"
                      v-model="vendorDetails.email"
                      background-color="#ffffff"
                      type="email"
                      outlined
                      hide-details="auto"
                      placeholder="Email"
                    ></v-text-field
                  ></v-col>
                  <v-col md="6" class="pr-0"
                    ><v-text-field
                      v-model="vendorDetails.phone"
                      :rules="[rules.required]"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="Phone"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <div class="d-flex justify-end py-8 px-4 mb-md-6">
                  <v-btn
                    class="mx-0 mr-md-5"
                    @click="sendVendorInvite"
                    dark
                    width="121"
                    height="45"
                    style="
                      background: var(--v-primary-base);
                      box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24);
                      border-radius: 4px;
                    "
                  >
                    <simple-line-icons
                      icon="arrow-right"
                      color="#FFFFFF"
                      style="
                        font-family: simple-line-icons;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 16px;
                      "
                      no-svg
                    />
                    <span
                      class="text-capitalize pl-3"
                      style="
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        text-align: center;
                        letter-spacing: 0.636364px;
                        color: #ffffff;
                      "
                      >Invite</span
                    >
                  </v-btn>
                </div>
              </v-form>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      dialog: false,

      vendorDetails: {
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        business: "",
      },

      tab: null,

      VendorTabs: ["Contact"],
      rules: {
        required: (v) => !!v || "This field is required",
        digit: (v) =>
          Number.isInteger(Number(v)) || "The value must be an integer number",
        email: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      },
    };
  },
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),

    validate() {
      this.$refs.form.validate();
    },
    async sendVendorInvite() {
      const inviteData = {
        organizationId: this.organizationId,
        payload: this.vendorDetails,
      };

      try {
        const res = await this.$store.dispatch(
          "contacts/inviteAVendor",
          inviteData
        );
        console.log(res);
        this.showToast({
          sclass: "success",
          show: true,
          message: "Vendor invitation sent..",
          timeout: 3000,
        });

        this.dialog = false;
      } catch (error) {
        console.log(error);

        this.showToast({
          sclass: "error",
          show: true,
          message: "failed to Update",
          timeout: 3000,
        });
      } finally {
        this.$router.push({ name: "Contacts" });
      }
    },
    show(value) {
      this.dialog = value;
    },
  },

  computed: {
    //getter
    ...mapGetters({
      organizationId: "auth/organizationId",
    }),

    //dynamic button size and icon
    size() {
      const size = { xs: "x-small", sm: "small", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    iconSize() {
      const size = { xs: "x-small", sm: "small" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
  },
};
</script>

<style scoped>
.chipText {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #7f919b;
}

.vendor-desc {
  color: var(--v-primary-base);
  display: block;
  padding: 20px 0px;
  font-size: 16px;
}

.headerText {
  margin-left: 52px;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.45px;
  color: #7f919b;
}

i.sli-font {
  font-size: 12px;
  display: inline-block;
}

.select {
  margin-right: 260px;
  margin-left: 52px;
}

.theme--light.v-tabs-items {
  background-color: transparent !important;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 0;
}
</style>
