<template>
  <div>
    <v-dialog v-model="dialog" max-width="574">
      <v-card color="#f8f7f4" class="rounded-lg">
        <v-form ref="creatCustomerForm">
          <v-card-title class="" style="background: #ffffff; border-radius: 8px 8px 0px 0px">
            <span style="
                font-family: Inter;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: #19283d;
              ">Add Customer</span>
            <v-spacer></v-spacer>
            <v-icon tag="button" @click="dialog = false" class="text-bolder" color="#596A73">
              mdi-close
            </v-icon>
          </v-card-title>
          <div class="px-8 d-flex" style="background: #f3eee8">
            <v-tabs slider-size="4" v-model="tab" background-color="#f3eee8">
              <v-tab v-for="item in CustomerType" :key="item">
                {{ item }}
              </v-tab>
            </v-tabs>
          </div>
          <v-tabs-items v-model="tab" slider-size="4" class="">
            <v-tab-item>
              <v-card style="background-color: transparent" flat>
                <div class="my-7 mx-4 mx-md-10 mb-6">
                  <p class="py-0 px-md-2 ma-0 customer-desc">
                    Include details about this customer, contact person,
                    business address, default payment terms, etc
                  </p>
                </div>
                <div class="customer-warn">
                  <img :src="require('@/assets/invoice-flag.svg')" />
                  <span>
                    A Virtual ACCOUNT will be issued for all invoices to this
                    customer.
                  </span>
                </div>
                <v-form ref="form">
                  <v-row class="mx-4 mx-md-10">
                    <v-col cols="6">
                      <v-text-field v-model="customerDetails.firstname" :rules="[rules.required]"
                        background-color="#ffffff" outlined hide-details="auto" placeholder="First Name"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field v-model="customerDetails.lastname" :rules="[rules.required]"
                        background-color="#ffffff" outlined hide-details="auto" placeholder="Last Name"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mx-4 mx-md-10">
                    <v-col class="">
                      <v-text-field v-model="customerDetails.email" :rules="[rules.required, rules.email]"
                        background-color="#ffffff" type="email" outlined hide-details="auto"
                        placeholder="Email"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mx-4 mx-md-10">
                    <v-col cols="6">
                      <v-text-field v-model="customerDetails.phone" :rules="[rules.required]" background-color="#ffffff"
                        outlined hide-details="auto" placeholder="Phone"></v-text-field>
                    </v-col>
                    <v-col cols="6" class="">
                      <v-text-field v-model="customerDetails.tax_number" background-color="#ffffff" outlined
                        hide-details="auto" placeholder="TIN"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-card-actions class="justify-end pa-8">
                    <v-btn class="mr-5" @click="switchTabs('next')" dark width="121" height="45" style="
                        background: var(--v-primary-base);
                        box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24);
                        border-radius: 4px;
                      ">
                      <simple-line-icons icon="arrow-right" color="#FFFFFF" style="
                          font-family: simple-line-icons;
                          font-style: normal;
                          font-weight: normal;
                          font-size: 12px;
                          line-height: 16px;
                        " no-svg />
                      <span class="text-capitalize pl-3" style="
                          font-family: Inter;
                          font-style: normal;
                          font-weight: 500;
                          font-size: 14px;
                          line-height: 17px;
                          text-align: center;
                          letter-spacing: 0.636364px;
                          color: #ffffff;
                        ">next</span>
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-tab-item>
            <!-- BUSINESS TAB -->
            <v-tab-item>
              <v-card style="background-color: transparent" flat>
                <div class="mt-7">
                  <v-row class="mx-4 ml-md-13 mr-md-10">
                    <v-col cols="12" class="">
                      <v-text-field v-model="customerDetails.company.company_name" background-color="#ffffff" outlined
                        hide-details="auto" placeholder="Company Name"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mx-4 ml-md-13 mr-md-10">
                    <v-col cols="12" class="">
                      <v-text-field v-model="customerDetails.address.address" background-color="#ffffff" outlined
                        hide-details="auto" placeholder="Address"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mx-4 mx-md-10">
                    <v-col cols="6" class="">
                      <v-text-field v-model.number="customerDetails.address.postcode" background-color="#ffffff"
                        outlined hide-details="auto" placeholder="Post code"></v-text-field>
                    </v-col>
                    <v-col cols="6" class="">
                      <v-text-field v-model="customerDetails.address.city" background-color="#ffffff" type="email"
                        outlined hide-details="auto" placeholder="City"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mx-4 mx-md-10">
                    <v-col cols="6">
                      <v-text-field v-model="customerDetails.address.state" background-color="#ffffff" outlined
                        hide-details="auto" placeholder="State"></v-text-field>
                    </v-col>
                    <v-col cols="6" class="">
                      <v-select v-model="customerDetails.address.country" :items="country" background-color="#ffffff"
                        outlined hide-details="auto" placeholder="Country"></v-select>
                    </v-col>
                  </v-row>
                  <div class="d-flex justify-end py-6 mb-8 px-7">
                    <v-btn class="mr-5" @click="switchTabs('next')" dark width="121" height="45" style="
                        background: var(--v-primary-base);
                        box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24);
                        border-radius: 4px;
                      ">
                      <simple-line-icons icon="arrow-right" color="#FFFFFF" style="
                          font-family: simple-line-icons;
                          font-style: normal;
                          font-weight: normal;
                          font-size: 12px;
                          line-height: 16px;
                        " no-svg />
                      <span class="text-capitalize pl-3" style="
                          font-family: Inter;
                          font-style: normal;
                          font-weight: 500;
                          font-size: 14px;
                          line-height: 17px;
                          text-align: center;
                          letter-spacing: 0.636364px;
                          color: #ffffff;
                        ">next</span>
                    </v-btn>
                  </div>
                </div>
              </v-card>
            </v-tab-item>
            <!-- Category TAB -->
            <v-tab-item>
              <v-card style="background-color: transparent" flat>
                <div class="mt-10">
                  <v-row class="mx-4 mx-md-10">
                    <v-col cols="6">
                      <v-select class=" text-capitalize" v-model="customerDetails.company.business_type"
                        :items="businessCategory" background-color="#ffffff" outlined hide-details="auto"
                        placeholder="Business Category"></v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field v-model="customerDetails.company.registration_number
                        " background-color="#ffffff" outlined hide-details="auto"
                        placeholder="Registration Number"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mx-4 mx-md-10">
                    <v-col cols="6" class="">
                      <v-select v-model="customerDetails.currency" :items="currency" background-color="#ffffff" outlined
                        hide-details="auto" placeholder="Currency"></v-select>
                    </v-col>
                    <v-col cols="6" class="">
                      <v-select class="text-capitalize" v-model="customerDetails.company.industry"
                        :items="customerIndustry" background-color="#ffffff" outlined hide-details="auto"
                        placeholder="Industry"></v-select>
                    </v-col>
                  </v-row>
                  <v-row class="mx-4 mx-md-10">
                    <v-col md="12" class="">
                      <v-text-field v-model="customerDetails.buyersystemid" background-color="#ffffff" outlined
                        hide-details="auto" placeholder="Customer Id"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mx-4 mx-md-10">
                    <v-col md="12" class="">
                      <TagsInputComponent :tagsArray="TagsArray" @addTag="addTag" @removeLastTag="removeLastTag" />
                    </v-col>
                  </v-row>

                  <div class="d-flex justify-end py-6 mt-3 mb-8 px-7">
                    <v-btn @click="createCustomer" :loading="creatingCustomer" dark width="121" height="45" class="mr-5"
                      style="
                        background: var(--v-primary-base);
                        box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24);
                        border-radius: 4px;
                      ">
                      <simple-line-icons icon="arrow-right" color="#FFFFFF" style="
                          font-family: simple-line-icons;
                          font-style: normal;
                          font-weight: normal;
                          font-size: 12px;
                          line-height: 16px;
                        " no-svg />
                      <span class="text-capitalize pl-3" style="
                          font-family: Inter;
                          font-style: normal;
                          font-weight: 500;
                          font-size: 14px;
                          line-height: 17px;
                          text-align: center;
                          letter-spacing: 0.636364px;
                          color: #ffffff;
                        ">Save</span>
                    </v-btn>
                  </div>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import contactDetails from "@/mixins/contactDetails.js";
import TagsInputComponent from "@/components/TagsInputComponent.vue";

export default {
  data() {
    return {
      dialog: false,
      creatingCustomer: false,
      customerDetails: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        tax_number: "",
        company: {
          company_name: "",
          registration_number: "",
          business_type: "",
          industry: "",
        },
        address: {
          address: "",
          city: "",
          state: "",
          country: "",
          postcode: "",
        },
        // terms: "",
        currency: "",
        buyersystemid: "",
      },

      selectedPaymentTerms: "",
      tab: null,

      CustomerType: ["Contact", "Business", "Category"],
      rules: {
        required: (v) => !!v || "This field is required",
        email: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        number: (v) =>
          (!isNaN(parseFloat(v)) && isFinite(v)) || "Value must be a number",
      },
    };
  },
  components: { TagsInputComponent },
  mixins: [contactDetails],
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),
    switchTabs(direction) {
      // console.log(direction);
      switch (direction) {
        case "next":
          if (this.tab <= this.CustomerType.length - 2) {
            this.tab++;
            return;
          }
          if (this.tab == this.CustomerType.length - 1) {
            this.saveAction();
          }
          break;

        default:
          break;
      }
    },

    //dispatches the create customer action
    async createCustomer() {
      const newTagArray = this.TagsArray.reduce((acc, cur) => {
        acc.push({ label: cur, tag_name: cur });
        return acc;
      }, []);
      console.log(JSON.stringify(newTagArray, null, 2));
      let data = {
        ...this.customerDetails,
        tag: newTagArray,
      };

      // Check if the form is valid
      if (this.$refs.creatCustomerForm.validate()) {
        this.creatingCustomer = true;
        try {
          await this.$store
            .dispatch("contacts/createNewCustomer", data)
            .then((res) => {
              if (res.status === "success") {
                this.showToast({
                  sclass: "success",
                  show: true,
                  message: "New Customer Created Successfully",
                  timeout: 3000,
                });
                this.dialog = false;
                this.$router.push({ name: "Contacts" });
              }
            });
        } catch (error) {
          console.log(error);
          this.showToast({
            sclass: "error",
            show: true,
            message:
              error.msg == "ERROR:" ? "Failed to Create Customer" : error.msg,
            timeout: 3000,
          });
        } finally {
          this.$store.dispatch("contacts/fetchAllCustomers", this.$orgId);
          this.creatingCustomer = false;
        }
      }
    },

    show(value) {
      this.dialog = value;
    },
  },
  computed: {
    createCustomerPayload() {
      return {
        ...this.customerDetails,
        terms: this.selectedPaymentTerms.replace("Net ", ""),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.chipText {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #7f919b;
}

.headerText {
  margin-left: 52px;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.45px;
  color: #7f919b;
}

.customer-desc {
  color: var(--v-primary-base);
  display: block;
  padding: 20px 0px;
  font-size: 16px;
}

.customer-warn {
  display: flex;
  align-items: center;
  background: #ffffff;
  border-left: 4px solid #5b67ba;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.05);
  padding: 20px;
  gap: 25px;
  margin: 20px 50px;

  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* or 150% */

    /* hyphen Blue */

    color: #19283d;
  }
}

i.sli-font {
  font-size: 12px;
  display: inline-block;
}

.select {
  margin-right: 260px;
  margin-left: 52px;
}

.theme--light.v-tabs-items {
  background-color: transparent !important;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 0;
}
</style>
